<template>
  <div class="sidebar_wrapper">
    <b-list-group>
      <b-list-group-item :class="(searchData.customer_type_id === customer.id) ? `active` : ``" @click="getServiceList(customer.id)" v-for="(customer, index) in customerTypeList" :key="index" href="javascript:">
        <img width="20px" :src="commonServiceBaseUrl + 'download-attachment?file=uploads/portaloriginal/' + customer.image" alt=""/>
        {{ checkBn ? customer.name_bn : customer.name }}
        <span class="badge badge-pill service_ind_counter">{{ $n(customer.service_count) }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../config/api_config'

export default {
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl
    }
  },
  computed: {
    checkBn () {
        return (this.$i18n.locale === 'bn') ? 1 : 0
    },
    customerTypeList () {
      return this.$store.state.Portal.customerTypeList.filter(item => item.status === 1)
    },
    searchData () {
      return this.$store.state.Portal.searchData
    }
  },
  methods: {
    async loadData () {
      this.$store.dispatch('Portal/mutatePortalLoad', true)
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/customer-type-list')
      this.$store.dispatch('Portal/mutatePortalLoad', false)
      if (result.success) {
        this.$store.dispatch('Portal/mutateCustomerTypeList', result.data)
      }
    },
    getServiceList (id) {
      const search = {
        org_id: 0,
        customer_type_id: id,
        service_category_id: 0
      }
      this.$store.dispatch('Portal/mutateSearch', search)
    }
  }
}
</script>
