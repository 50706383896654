<template>
  <div class="sidebar_wrapper">
    <b-list-group>
      <b-list-group-item :title="checkBn ? org.org_name_bn : org.org_name" :class="(searchData.org_id === org.id) ? `active` : ``" @click="getServiceList(org.id)" v-for="(org, index) in orgList" :key="index" href="javascript:">
        <img width="20px" :src="commonServiceBaseUrl + org.logo" alt=""/>
        {{ checkBn ? org.abbreviation_bn : org.abbreviation }}
        <span class="badge badge-pill service_ind_counter">{{ $n(org.service_count) }}</span>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../../config/api_config'

export default {
  created () {
    this.loadData()
  },
  data () {
    return {
      commonServiceBaseUrl: commonServiceBaseUrl
    }
  },
  computed: {
    checkBn () {
        return (this.$i18n.locale === 'bn') ? 1 : 0
    },
    orgList () {
      return this.$store.state.Portal.orgList.filter(item => item.status === 0)
    },
    searchData () {
      return this.$store.state.Portal.searchData
    }
  },
  methods: {
    countTotal (cateId) {
      return this.$store.state.Portal.serviceData.serviceList.filter(service => service.service_category_id === parseInt(cateId)).length
    },
    async loadData () {
      this.$store.dispatch('Portal/mutatePortalLoad', true)
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/org-list')
      this.$store.dispatch('Portal/mutatePortalLoad', false)
      if (result.success) {
        this.$store.dispatch('Portal/mutateOrgList', result.data)
      }
    },
    getServiceList (id) {
      const search = {
        org_id: id,
        customer_type_id: 0,
        service_category_id: 0
      }
      this.$store.dispatch('Portal/mutateSearch', search)
    }
  }
}
</script>
