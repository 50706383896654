<template>
  <div class="services_section">
    <div class="cover_image_wrapper">
      <h2 class="cover_title">{{ checkBn ?  getParentName(comp, parentId).name_bn : getParentName(comp, parentId).name }}</h2>
      <img src="../../../assets/images/portal/cover-img.jpg" alt="" />
    </div>
    <div class="breadcrumb_wrapper">
      <b-container>
        <b-row>
          <b-col>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/portal/home">{{ $t('portal.home') }}</router-link></li>
                <li class="breadcrumb-item active" aria-current="page">{{ $t('portal.services') }}</li>
              </ol>
            </nav>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="menu_bar_wrapper">
      <b-container>
        <b-row class="menu_bar p-0">
          <b-col sm='8' md="8" lg="8" class="menubar_col">
            <div class="menu_left">
              <ul>
                <li><a href="javascript:" @click="loadComp('customerTypeList')" :class="(comp === 'customerTypeList') ? `active` : ``">{{ $t('portal.service_for_whom') }}</a></li>
                <li><a href="javascript:" @click="loadComp('categoryList')" :class="(comp === 'categoryList') ? `active` : ``">{{ $t('portal.what_kind_of_service') }}</a></li>
                <li><a href="javascript:" @click="loadComp('orgList')" :class="(comp === 'orgList') ? `active` : ``">{{ $t('portal.which_org_service') }}</a></li>
                <li><router-link to="/portal/all-services" >{{ $t('portal.all_service') }} <span v-if="totalService"> ({{ $n(totalService) }})</span></router-link></li>
              </ul>
            </div>
          </b-col>
          <b-col sm='4' md="4" lg="4" class="menubar_col order-first order-sm-last">
            <div class="menu_right">
              <div class="position-relative">
                <b-form-input
                  size="sm"
                  v-model="service_name"
                  class="mr-sm-2 portal_searchbar"
                  :placeholder="$t('portal.find_service')"
                ></b-form-input>
                <!-- <i class="fas fa-search search_icon"></i> -->
                <img
                  src="../../../assets/images/search-bar.png"
                  class="search_icon"
                  alt=""
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div class="service_container_wrapper">
      <b-container>
        <b-row>
          <b-col sm="3">
            <component :is="comp" > </component>
          </b-col>
          <b-col sm="9">
            <div v-if="loading">
              <Services/>
            </div>
            <div v-else v-for="(service, index) in filterServiceList" :key="index" class="service_list_wrapper">
              <div class="card card_wrapper">
                <div class="card-header">
                  <h5 class="title">{{ ($i18n.locale === 'bn') ? service.name_bn : service.name }}</h5>
                  <div>
                    <span v-if="service.status" class="badge badge-light">{{ $t('portal.active_service') }}</span>
                    <span v-else class="badge badge-light">{{ $t('portal.upcoming_service') }}</span>
                  </div>
                </div>
                <div class="row no-gutters">
                  <div class="col-md-9">
                    <div class="card-body">
                      <p class="card-text text-justify mb-0" v-html="($i18n.locale === 'bn' ? truncate(service.description_bn, 250) : truncate(service.description, 250)) + '...'">
                      </p>
                      <router-link :to="`/portal/service-details?service_type=${comp}&${parentName}=${parentId}&service_id=${service.id}`" class="card-text details_link"
                        ><p class="read_more d-inline-block mb-0">
                          {{ $t('portal.read_more') }}
                          <i class="fas fa-long-arrow-alt-right"></i></p
                      ></router-link>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="apply_wrapper">
                      <a href="javascript:void" v-if="service.pr_apply_btn" @click="setServiceUrl(service.service_url, index)" class="apply_btn">
                        <slot v-if="!service.apply_or_view">
                          {{ $t('agri_portal.apply') }} <i class="fas fa-paper-plane"></i>
                        </slot>
                        <slot v-else>
                          {{ $t('agri_portal.view') }} <i class="fas fa-long-arrow-alt-right"></i>
                        </slot>
                      </a>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-footer">
                  <span> {{ $t('portal.applied_for_service') }} : <span class="counter"> {{ $n(service.applied_service) }} </span> {{ $t('portal.person') }}</span>
                  <span class="line_border d-none d-sm-inline-block"></span>
                  <span>{{ $t('portal.got_the_service') }} : <span class="counter">{{ $n(service.received_service) }}</span> {{ $t('portal.person') }}</span>
                </div> -->
              </div>
            </div>
            <div v-if="!filterServiceList.length" class="service_item text-center pt-5">
              <h5>{{ $t('globalTrans.noDataFound') }}</h5>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>
<script>
import RestApi, { commonServiceBaseUrl } from '../../../config/api_config'
import customerTypeList from '../components/services/CustomerType'
import categoryList from '../components/services/CategoryList'
import orgList from '../components/services/OrgList'
import Common from '@/mixins/portal/common'
import Services from '../components/services/loading/service/services'
export default {
  mixins: [Common],
  components: {
    customerTypeList,
    categoryList,
    orgList,
    Services
  },
  created () {
    const search = {
        org_id: 0,
        customer_type_id: 0,
        service_category_id: 0
      }
      Object.keys(this.$route.query).map(key => {
        search[key] = parseInt(this.$route.query[key])
      })
    this.$store.dispatch('Portal/mutateSearch', search)
    this.setComp(this.$route.query)
    this.loadData()
  },
  data () {
    return {
      service_name: '',
      parentId: '',
      parentName: '',
      comp: 'customerTypeList',
      loading: false,
      filterServiceList: []
    }
  },
  watch: {
    searchData: function (newVal, oldVal) {
      this.loadData(newVal)
      this.setComp(this.$store.state.Portal.searchData)
    },
    service_name: function (newVal, oldVal) {
      if (newVal) {
        const service = this.serviceList.filter(el => {
          if (el.name.toLowerCase().includes(newVal.toLowerCase()) || el.name_bn.toLowerCase().includes(newVal.toLowerCase())) {
            return el
          }
        })
        this.filterServiceList = service
      } else {
        this.filterServiceList = [...this.serviceList]
      }
    }
  },
  computed: {
    totalService () {
      return this.$store.state.Portal.serviceData.total_services
    },
    serviceList () {
      return this.$store.state.Portal.serviceList
    },
    searchData () {
      return this.$store.state.Portal.searchData.loading
    }
  },
  methods: {
    async loadData () {
      this.loading = true
      this.service_name = ''
      const params = this.$store.state.Portal.searchData
      const result = await RestApi.getData(commonServiceBaseUrl, '/portal/frontend/service-list', params)
      this.loading = false
      if (result.success) {
        this.$store.dispatch('Portal/mutateServiceList', result.data)
        this.filterServiceList = this.$store.state.Portal.serviceList
      }
    },
    loadComp (comp) {
      this.comp = comp
       const tmpSearch = this.setSearch(comp)
      this.$store.dispatch('Portal/mutateSearch', tmpSearch)
      this.loadData()
    },
    setComp (qRoute) {
      if (qRoute) {
        if (qRoute.customer_type_id) {
          this.comp = 'customerTypeList'
          this.parentId = qRoute.customer_type_id
          this.parentName = 'customer_type_id'
        } else if (qRoute.service_category_id) {
          this.comp = 'categoryList'
          this.parentId = qRoute.service_category_id
          this.parentName = 'service_category_id'
        } else {
          this.comp = 'orgList'
          this.parentId = qRoute.org_id
          this.parentName = 'org_id'
        }
      }
    },
    truncate (str, n) {
      return (str.length > n) ? str.substr(0, n - 1) + '' : str
    },
    setSearch (comp) {
      const search = {
          org_id: 0,
          customer_type_id: 0,
          service_category_id: 0
        }
      if (comp === 'customerTypeList') {
        const firstCus = this.$store.state.Portal.customerTypeList.slice(0, 1).shift()
        return Object.assign(search, { customer_type_id: firstCus.id })
      } else if (comp === 'categoryList') {
        const firstCat = this.$store.state.Portal.categoryList.slice(0, 1).shift()
        return Object.assign(search, { service_category_id: firstCat.id })
      } else {
        const firstOrg = this.$store.state.Portal.orgList.slice(0, 1).shift()
        return Object.assign(search, { org_id: firstOrg.id })
      }
    },
    setServiceUrl (serviceUrl, index) {
      if (serviceUrl === '/trainee-trainer-panel/profile') {
        this.$store.commit('ExternalUserIrrigation/setTrainingPanel', {
          trainingType: 1,
          isTraner: 1
        })
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('market-directory') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('research-proposal-circular-list') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('training') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('venue-reservation') !== -1) {
        this.$router.push(serviceUrl)
        return
      }
      if (serviceUrl.indexOf('incentive-farmer') !== -1) {
        // this.$router.push(nextRoute)
        this.$router.push({ name: 'portal.circular_list', params: { serviceUrl: serviceUrl } })
        return
      }
      if (serviceUrl.indexOf('research-farmer') !== -1 && serviceUrl.indexOf('proposal-submission-form') !== -1) {
        this.$router.push('/portal/publication/research-circular-list')
        return
      }
      if (serviceUrl.indexOf('research-farmer') !== -1 && serviceUrl.indexOf('publication-proposal-submission') !== -1) {
        this.$router.push('/portal/publication?home=true')
        return
      }
      // Virtual Crop Pest Museum
      if (serviceUrl.indexOf('http://service.moa.gov.bd:8095/') !== -1) {
        window.open(serviceUrl, '_blank')
        return
      }
      if (serviceUrl === 'http://www.frs-bd.com/' || serviceUrl === 'http://103.101.199.133/') {
        window.open(serviceUrl, '_blank')
        return
      }
      this.$store.dispatch('Portal/setServiceUrl', {
        externalPanelServiceUrl: serviceUrl,
        externalPanelCurrentServiceUrl: serviceUrl
      })
      this.$router.push('/auth/login')
    }
  }
}
</script>
